import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { NavigationButton } from './NavigationButton';
import {
  HomeIcon,
  HomeIconFilled,
  MenuIcon,
  FavoritesIcon,
  FavoritesIconFilled,
  TicketsTabIcon,
  TicketsTabIconFilled
} from '@gf/cross-platform-lib/components';
import { useRouter, useNavigate } from '@gf/cross-platform-lib/hooks';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { styles } from './MobileFooter.styles';
import { useSearchPageContext } from '@gf/cross-platform-lib/providers';
import { MotiView as View } from 'moti';
import { bottomNavigator, testProperties } from '@gf/cross-platform-lib/utils';
import { Keyboard } from 'react-native';

interface FooterProps {}

export const MobileFooter = ({}: FooterProps) => {
  const navigate = useNavigate();
  const { footerVisible } = useSearchPageContext();
  const { currentRoute } = useRouter();

  const isActive = (path: string) => currentRoute === path;
  const activeColor = (path: string) => (isActive(path) ? '#4A4A4B' : '#191919');

  const [currentTab, setCurrentTab] = React.useState(currentRoute);
  return (
    <Wrapper
      animate={{
        translateY: footerVisible ? 0 : 44
      }}
      transition={{
        type: 'timing',
        duration: 250
      }}
    >
      <Footer>
        <NavigationButton
          onClick={async () => {
            setCurrentTab(currentRoute);
            await navigate('/');
          }}
          label='Home'
          icon={
            currentTab === GOFAN_APP_PAGES.home.path ? (
              <HomeIconFilled color={activeColor('/')} />
            ) : (
              <HomeIcon color={activeColor('/')} />
            )
          }
          active={isActive('/')}
          {...testProperties(bottomNavigator.home)}
        />
        <NavigationButton
          label='Tickets'
          onClick={() => {
            setCurrentTab(currentRoute);
            navigate(GOFAN_APP_PAGES.myTickets.path);
          }}
          icon={
            currentTab === GOFAN_APP_PAGES.myTickets.path ? (
              <TicketsTabIconFilled color={activeColor(GOFAN_APP_PAGES.myTickets.path)} />
            ) : (
              <TicketsTabIcon color={activeColor(GOFAN_APP_PAGES.myTickets.path)} />
            )
          }
          active={isActive(GOFAN_APP_PAGES.myTickets.path)}
          {...testProperties(bottomNavigator.tickets)}
        />
        <NavigationButton
          onClick={async () => {
            setCurrentTab(currentRoute);
            await navigate(GOFAN_APP_PAGES.mySchool.path);
          }}
          label='Favorites'
          icon={
            currentTab === GOFAN_APP_PAGES.mySchool.path ? (
              <FavoritesIconFilled color={activeColor(GOFAN_APP_PAGES.mySchool.path)} />
            ) : (
              <FavoritesIcon color={activeColor(GOFAN_APP_PAGES.mySchool.path)} />
            )
          }
          active={isActive(GOFAN_APP_PAGES.mySchool.path)}
          {...testProperties(bottomNavigator.schools)}
        />
        <NavigationButton
          label='Profile'
          onClick={() => {
            Keyboard.dismiss();
            setCurrentTab(currentRoute);
            navigate(GOFAN_APP_PAGES.profile.path);
          }}
          icon={<MenuIcon />}
          active={false}
          {...testProperties(bottomNavigator.more)}
        />
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled(View)`
  ${styles.wrapper}
`;
const Footer = styled(View)`
  ${styles.footer}
`;
